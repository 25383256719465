import { PhoneNumber } from 'gabi-api-ts/v2/hcp/common/hcp_common';
import {AsYouType} from 'libphonenumber-js';
import {ChangeEvent, ChangeEventHandler, FocusEventHandler} from 'react';

import {createSyntheticChangeEvent} from '@/util/event-util';
import {parsePhoneNumberFromLibSafely} from '@/util/phonenumber-util';

type PhoneNumberWithInputInfo = PhoneNumber & {
    empty?: boolean;
};

function formatPhoneNumber(phoneNumber?: PhoneNumber) {
    if (!phoneNumber || (phoneNumber as PhoneNumberWithInputInfo).empty) {
        return '';
    }
    const phoneNumberStr = (phoneNumber ? `+${phoneNumber.countryCode ?? ''}${phoneNumber.number ?? ''}` : '').trim();
    if (phoneNumberStr === '+') {
        return '+';
    }
    else {
        return new AsYouType().input(phoneNumberStr);
    }
}

function parsePhoneNumber(phoneNumber: string) {
    const parsedFromLib = parsePhoneNumberFromLibSafely(phoneNumber);
    if (parsedFromLib) {
        return {
            number: parsedFromLib.nationalNumber,
            countryCode: parsedFromLib.countryCallingCode,
        };
    }
    else {
        return null;
    }
}

type PhonenumberInputProps = {
    id?: string;
    className?: string;
    value?: PhoneNumber;
    disabled?: boolean;
    onChange: ChangeEventHandler<{value: PhoneNumberWithInputInfo}>;
    onBlur?: FocusEventHandler;
};
function PhonenumberInput({id, className, value, disabled, onChange, onBlur}: PhonenumberInputProps) {
    function triggerEvent(newValue: PhoneNumberWithInputInfo) {
        const customEvent = createSyntheticChangeEvent<PhoneNumberWithInputInfo>(newValue);
        if (onChange) {
            onChange(customEvent);
        }
    }

    const handleChangePhoneNumber = (e: ChangeEvent<HTMLInputElement>) => {
        const phonenum = e?.target?.value;
        if (phonenum) {
            const parsedPhoneNumber = parsePhoneNumber(phonenum);

            if (parsedPhoneNumber) {
                triggerEvent(parsedPhoneNumber);
            }
        }
        else if (phonenum === '') {
            triggerEvent({
                countryCode: '',
                number: '',
                empty: true,
            });
        }
    };

    const phoneNumberStr = formatPhoneNumber(value);

    return (
        <input
            id={id}
            maxLength={20}
            className={`phonenumber-input ${className ?? ''}`}
            required aria-required="true"
            value={phoneNumberStr}
            placeholder="+1 555 555 1234"
            disabled={disabled}
            onChange={handleChangePhoneNumber}
            onBlur={onBlur}
        />
    );
}

export type {PhoneNumberWithInputInfo};
export {PhonenumberInput};
