import type {ChangeEvent} from 'react';

type SyntheticEventCustomTarget<Val> = EventTarget & Element & { value: Val };

export const createSyntheticChangeEvent =
<Val>(value: Val): ChangeEvent<{value: Val}> => {
    const event: ChangeEvent = new Event('change', { bubbles: true }) as unknown as ChangeEvent;
    Object.defineProperty(event, 'target', {writable: false, value: {value: value}});
    let isDefaultPrevented = false;
    let isPropagationStopped = false;
    const preventDefault = () => {
        isDefaultPrevented = true;
        event.preventDefault();
    };
    const stopPropagation = () => {
        isPropagationStopped = true;
        event.stopPropagation();
    };
    return {
        nativeEvent: event.nativeEvent,
        currentTarget: event.currentTarget as SyntheticEventCustomTarget<Val>,
        target: event.target as SyntheticEventCustomTarget<Val>,
        bubbles: event.bubbles,
        cancelable: event.cancelable,
        defaultPrevented: event.defaultPrevented,
        eventPhase: event.eventPhase,
        isTrusted: event.isTrusted,
        preventDefault,
        isDefaultPrevented: () => isDefaultPrevented,
        stopPropagation,
        isPropagationStopped: () => isPropagationStopped,
        persist: () => {},
        timeStamp: event.timeStamp,
        type: event.type,
    };
};
