import PropTypes from 'prop-types';
import React from 'react';
import autoBind from 'react-autobind';
import {withTranslation} from 'react-i18next';

import {PatientCaregiverForm} from '@/components/business/common/patient/patient-caregiver-form';
import {SuccessMessage} from '@/components/form/success-message';
import {BackendApiService} from '@/services/backend-api-service';

class PrivacyUpdatePersonalDataCaregiver extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        t: PropTypes.func,
        patientData: PropTypes.object.isRequired,
        caregiverData: PropTypes.object.isRequired,
        onSaved: PropTypes.func,
    };

    state = {
        loading: false,
        successMessage: '',
    };

    constructor(props) {
        super(props);
        autoBind(this);
    }

    render() {
        const props = this.props;
        const state = this.state;
        const { t } = this.props;

        return (
            <div className={props.className}>
                <h3>{t(['Caregiver information', 'updateCaregiver.title'])}</h3>
                {state.successMessage &&
                    <SuccessMessage successMessage={state.successMessage}/>
                }
                <PatientCaregiverForm
                    patient={props.patientData}
                    caregiver={props.caregiverData}
                    loading={state.loading}
                    onSubmit={(personalData) => this.updatePatientPersonalData(personalData)}
                />
            </div>
        );
    }
    
    updatePatientPersonalData(caregiverData) {
        const { t } = this.props;
        
        this.setState(state => ({
            ...state,
            loading: true,
        }));

        BackendApiService.getRequest({
            domain: 'privacy',
            modelName: 'privacyUpdatePersonalDataCaregiver',
            data: {
                caregiver: caregiverData,
            }
        }).then(async () => {
            if (this.props.onSaved) {
                await this.props.onSaved(caregiverData);
            }
            this.setState(state => ({
                ...state,
                loading: false,
                successMessage: t('updateCaregiver.updateSuccess'),
            }));
        }).catch(err => {
            console.error(err);
            this.setState(state => ({
                ...state,
                loading: false,
            }));
        });
    }
}

PrivacyUpdatePersonalDataCaregiver = withTranslation()(PrivacyUpdatePersonalDataCaregiver);
export {PrivacyUpdatePersonalDataCaregiver};
