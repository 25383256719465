import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';

import patientAvatar_skinType_0 from '@/public/images/baby_avatar_skin_0.svg';
import patientAvatar_skinType_1 from '@/public/images/baby_avatar_skin_1.svg';
import patientAvatar_skinType_2 from '@/public/images/baby_avatar_skin_2.svg';
import patientAvatar_skinType_3 from '@/public/images/baby_avatar_skin_3.svg';
import patientAvatar_skinType_4 from '@/public/images/baby_avatar_skin_4.svg';
import patientAvatar_skinType_5 from '@/public/images/baby_avatar_skin_5.svg';
import patientAvatar_skinType_6 from '@/public/images/baby_avatar_skin_6.svg';

const PatientAvatar = ({skinType}) => {
    const { t } = useTranslation();

    const patientPicSkinType = {
        0: patientAvatar_skinType_0,
        1: patientAvatar_skinType_1,
        2: patientAvatar_skinType_2,
        3: patientAvatar_skinType_3,
        4: patientAvatar_skinType_4,
        5: patientAvatar_skinType_5,
        6: patientAvatar_skinType_6,
    };

    return (
        <img
            src={patientPicSkinType[skinType]}
            width={50}
            alt={t(['Illustration of a baby\'s face', 'pages.patients.alt'])}
        />
    );
};

PatientAvatar.propTypes = {
    skinType: PropTypes.any,
};

export default PatientAvatar;
