import PropTypes from 'prop-types';
import React from 'react';
import autoBind from 'react-autobind';
import {withTranslation} from 'react-i18next';
import Styled from 'styled-components';

import {Modal} from '@/components/layout/modal';
import {AppLogoFull} from '@/components/static/app-logo-full';
import LegalMentions from '@/components/static/legal-mentions';
import homeImage from '@/public/icons/home-icon.svg';
import {colorPalette} from '@/themes/darkmode';
import {appVersion} from '@/util/app-version-util';

class MainTopbarSimple extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        t: PropTypes.func,
        children: PropTypes.any,
        title: PropTypes.string.isRequired,
    };

    state = {
        displayModalLegalMentions: false,
    };

    constructor(props) {
        super(props);
        autoBind(this);
    }

    render() {
        const props = this.props;
        const state = this.state;
        const { t } = this.props;

        return (
            <header className={props.className}>
                {state.displayModalLegalMentions &&
                    <Modal title={t(['Legal Mentions', 'legalMentions.title'])} onClosed={this.handleCloseModal}>
                        <LegalMentions />
                    </Modal>
                }

                <div className="topbar-app-logo">
                    <AppLogoFull />
                </div>
                <h1>{props.title}</h1>
                <a href="#" className="topbar-app-version" onClick={this.handleClickVersion}>v{appVersion}</a>
            </header>
        );
    }

    handleCloseModal() {
        this.setState(state => ({
            ...state,
            displayModalLegalMentions: false,
        }));
    }

    handleClickVersion(e) {
        e.preventDefault();

        this.setState(state => ({
            ...state,
            displayModalLegalMentions: true,
        }));
    }

    shouldComponentUpdate() {
        return true; // Layout component, has children
    }
}

//language=SCSS
MainTopbarSimple = Styled(MainTopbarSimple)`
& {
    position: fixed;
    top:0;
    left:0;
    height:80px;
    width:100%;
    display: flex;
    background:${colorPalette.thirdBackground};
    z-index: 1000;
    
    @media print {
        position:relative;
    }
    
    .topbar-home-link {
        background: url(${homeImage}) no-repeat center;
        display: inline-block;
        width: 80px;
        height: 80px;
        border-right: 3px solid ${colorPalette.mainBackground};
        &:hover, &:active, &:visited {
            background-color:${colorPalette.activeColor};
        }
    }
    
    .topbar-app-logo, h1 {
        margin-left:20px;
        margin-top:15px;
    }
    
    .topbar-app-version {
        position:absolute;
        right:20px;
        top:27px;
        color: ${colorPalette.activeColor};
    }
}
`;

export default withTranslation()(MainTopbarSimple);
