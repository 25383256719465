import PropTypes from 'prop-types';
import React from 'react';
import {withTranslation} from 'react-i18next';
import Styled from 'styled-components';

import instructionSymbol from '@/public/icons/instruction-symbol.svg';
import lotSymbol from '@/public/icons/lot-symbol.svg';
import manufacturerSymbol from '@/public/icons/manufacturer-symbol.svg';
import mdSymbol from '@/public/icons/md-symbol.svg';
import udiSymbol from '@/public/icons/udi-symbol.svg';
import {appVersion} from '@/util/app-version-util';

const appVersionDate = __APP_VERSION_DATE__;

class LegalMentions extends React.PureComponent {
    static propTypes = {
        t: PropTypes.func,
        className: PropTypes.string,
    };
    
    render() {
        const props = this.props;
        const { t } = this.props;

        return (
            <div className={props.className}>
                <div>
                    <span>{t(['Name', 'legalMentions.name'])}: Gabi Analytics</span>
                </div>
                <div>
                    <span className="legal-title"><img src={lotSymbol} width={35} alt="LOT symbol" /></span>
                    <span>v{appVersion} {appVersionDate}</span>
                </div>
                <div>
                    <span className="legal-title"><img src={udiSymbol} width={35} alt="UDI symbol" /></span>
                    <span>(01) 05430003002018<br />(10) v{appVersion} {appVersionDate}</span>
                </div>
                <div>
                    <span className="legal-title"><img src={manufacturerSymbol} width={25} alt="Manufacturer symbol" /></span>
                    <span>GABI SMARTCARE&reg; SA<br />Rue Emile Francqui, 6<br />1435 Mont-Saint-Guibert (Belgium)</span>
                </div>
                <div>
                    <img src={mdSymbol} width={50} alt="MD symbol" />
                    <img id="instructionSymbol" src={instructionSymbol} width={40} alt="Download IFU symbol" />
                </div>
                <div>
                    <span>{t(['FDA Cleared', 'legalMentions.fdaApproved'])}</span>
                </div>
            </div>
        );
    }
}

//language=SCSS
LegalMentions = Styled(LegalMentions)`
& {
    div {
        margin-bottom:15px;
        span {
            display:inline-block;
            vertical-align: top;
            &:first-child {
                margin-right:25px;
            }
            &.legal-title {
                width:50px;
            }
        }
    }

    #instructionSymbol {
        margin-left:20px;
    }
}
`;

export default withTranslation()(LegalMentions);
