import {PhoneNumber} from 'gabi-api-ts/v2/hcp/common/hcp_common';
import {
    parseIncompletePhoneNumber,
    parsePhoneNumber as libPhoneParsePhoneNumber,
    PhoneNumber as LibPhoneNumber
} from 'libphonenumber-js';

export function parsePhoneNumberFromLibSafely(phoneNumber: string) {
    try {
        return libPhoneParsePhoneNumber(phoneNumber);
    }
    catch (e) {
        try {
            const out = parseIncompletePhoneNumber(phoneNumber);
            return {
                countryCallingCode: out.substring(1),
                isValid: () => false,
            } as LibPhoneNumber;
        }
        catch (e) {
            return null;
        }
    }
}

export function isPhonenumberValid(phoneNumber?: PhoneNumber) {
    if (!phoneNumber) {
        return false;
    }
    const phoneNumberStr = `+${phoneNumber.countryCode ?? ''}${phoneNumber.number ?? ''}`;
    return parsePhoneNumberFromLibSafely(phoneNumberStr)?.isValid() ?? false;
}
