import ReactDOM from 'react-dom/client';
import {createBrowserRouter, RouterProvider} from 'react-router-dom';
import {ThemeProvider} from 'styled-components';

import routes from '@/routes-privacy.js';
import '@/services/i18n-service.js';
import * as theme from '@/themes/darkmode.js';

// Get application routes
const router = createBrowserRouter(routes);

// ENTRY POINT OF GABI ANALYTICS APPLICATION
ReactDOM.createRoot(document.getElementById('app')!).render(
    <ThemeProvider theme={Object.assign({}, theme)}>
        <RouterProvider router={router} />
    </ThemeProvider>
);
