import PropTypes from 'prop-types';
import React from 'react';
import autoBind from 'react-autobind';
import {withTranslation} from 'react-i18next';

import {Button} from '@/components/buttons/button';

class ConfirmDeleteForm extends React.Component {
    static propTypes = {
        loading: PropTypes.bool.isRequired,
        t: PropTypes.func,
        itemName: PropTypes.string.isRequired,
        deleteLabel: PropTypes.string.isRequired,
        text: PropTypes.string,
        onSubmit: PropTypes.func,
        onCancelled: PropTypes.func,
    };

    constructor(props) {
        super(props);
        autoBind(this);
    }

    render() {
        const props = this.props;
        const { t } = this.props;

        return (
            <>
                <form onSubmit={this.handleSubmit}>
                    <div className="fieldWrapper">
                        <p>{props.text || t(['Are you sure you want to delete', 'global.deleteConfirmation'])} <strong>{props.itemName}</strong>?</p>
                    </div>
                    <div>
                        <Button className="button-delete" loading={props.loading}>{props.deleteLabel}</Button>
                        <Button className="button-cancel" onClick={this.handleCancel}>{t(['Cancel', 'global.cancel'])}</Button>
                    </div>
                </form>
            </>
        );
    }

    handleSubmit(e) {
        e.preventDefault();
        if(this.props.onSubmit) {
            this.props.onSubmit();
        }
    }

    handleCancel(e) {
        e.preventDefault();
        if(this.props.onCancelled) {
            this.props.onCancelled();
        }
    }
}

export default withTranslation()(ConfirmDeleteForm);
