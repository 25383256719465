import {useState} from 'react';

/**
 * Helper hook to force a rerender by adding a dummy state
 */
export function useTriggerRender(): () => void {
    const [, setTriggerRenderCounter] = useState(0);
    return () => {
        setTriggerRenderCounter(counter => counter+1);
    };
}
