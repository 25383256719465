import {useTranslation} from 'react-i18next';
import Styled from 'styled-components';

import ErrorBoundary from '@/components/errors/error-boundary';
import {InfoMessage} from '@/components/form/info-message';
import invalidToken from '@/public/images/invalid-token.png';
import {colorPalette} from '@/themes/darkmode';

type PersonalDataErrorPageProps = {
    className?: string;
};

function PersonalDataErrorPageUnstyled(props :PersonalDataErrorPageProps) {
    const { t } = useTranslation();

    return (
        <div className={`personal-data-error ${props.className ?? ''}`}>
            <ErrorBoundary>
                <div className="img-wrapper">
                    <InfoMessage infoMessage={t('pages.privacy.personalDataError.error')} text={t('pages.privacy.personalDataError.errorText')} />
                    <img src={invalidToken} alt={t('pages.privacy.personalDataError.instructions') ?? undefined} />
                </div>
            </ErrorBoundary>
        </div>
    );
}

//language=SCSS
const PersonalDataErrorPage = Styled(PersonalDataErrorPageUnstyled)`
& {
    position: relative;
    padding: 80px 0 0 0;
    text-align: center;
    border-bottom: solid 1px ${colorPalette.thirdBackground};
    &:after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        height: 50px;
        width: 100%;
        background: linear-gradient(0deg, rgba(0,0,0,0.08), rgba(0,0,0,0.0));
    }
    
    .info-message {
        font-size: 1.1rem;
    }
    
    > .img-wrapper {
        margin: 0 auto;
        width: 600px;
    }
    
    img {
        margin-top: 25px;
    }
}
`;

export {PersonalDataErrorPage};
