import React, {ChangeEvent, ChangeEventHandler, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import Styled from 'styled-components';

import {Message, MessageType} from '@/components/form/message';
import {createSyntheticChangeEvent} from '@/util/event-util';

type DateInputValue = {
    day?: string | number;
    month?: string | number;
    year?: string | number;
};

enum DateInputFillState {
    EMPTY,
    PARTIALLY_FILLED,
    FILLED,
}

type DateInputValidationResult = {
    filled: DateInputFillState;
    dayValid: boolean;
    monthValid: boolean;
    yearValid: boolean;
    validationIssues: { message: string }[];
};

type DateInputProps = {
    className?: string;
    name?: string;
    value?: DateInputValue;
    onChange: ChangeEventHandler<{value: DateInputValue}>;
    minYear?: number;
    maxYear?: number;
};

function validateDate(value: DateInputValue, minYear: number | undefined, maxYear: number | undefined, t: (keys: string[], args?: object) => string): DateInputValidationResult {
    //Normalizing the value
    value = value ?? {};
    value = {...value};
    value.day = value.day ?? '';
    value.month = value.month ?? '';
    value.year = value.year ?? '';

    // Empty state
    if (value.day === '' && value.month === '' && value.year === '') {
        return {
            filled: DateInputFillState.EMPTY,
            dayValid: true,
            monthValid: true,
            yearValid: true,
            validationIssues: [],
        };
    }

    const dayNum = parseInt(`${value.day}`, 10);
    const monthNum = parseInt(`${value.month}`, 10);
    let yearNum = parseInt(`${value.year}`, 10);

    // If the year is a number and less than 4 chars long, consider it not filled
    if (!isNaN(yearNum) && `${value.year}`.length < 4) {
        value.year = '';
        yearNum = parseInt(`${value.year}`, 10);
    }

    const out: DateInputValidationResult = {
        filled: DateInputFillState.PARTIALLY_FILLED,
        dayValid: true,
        monthValid: true,
        yearValid: true,
        validationIssues: [],
    };

    // Input is filled
    if (value.day !== '' && value.month !== '' && value.year !== '') {
        out.filled = DateInputFillState.FILLED;
    }

    // Dates are numeric
    if ((value.day !== '' && isNaN(dayNum)) || (value.month !== '' && isNaN(monthNum)) || (value.year !== '' && isNaN(yearNum))) {
        out.validationIssues.push({ message: t(['Date must be in the numerical format', 'dateInput.validation.numerical'])});
        if ((value.day !== '' && isNaN(dayNum))) {
            out.dayValid = false;
        }
        if ((value.month !== '' && isNaN(monthNum))) {
            out.monthValid = false;
        }
        if ((value.year !== '' && isNaN(yearNum))) {
            out.yearValid = false;
        }
    }

    // Days must be valid within the selected month (or 31 if month is not selected yet)
    let dayInvalid: boolean = false;
    dayInvalid ||= ((isNaN(monthNum) || monthNum > 12 || monthNum === 1 || monthNum === 3 || monthNum === 5 || monthNum === 7 || monthNum === 8 || monthNum === 10 || monthNum === 12) && dayNum > 31);
    dayInvalid ||= (monthNum === 2 && (((yearNum % 4 === 0 && yearNum % 100 !== 0) || yearNum % 400 === 0) ? dayNum > 29 : dayNum > 28));
    dayInvalid ||= ((monthNum === 4 || monthNum === 6 || monthNum === 9 || monthNum === 11) && dayNum > 30);
    dayInvalid ||= dayNum < 1;
    if (dayInvalid) {
        out.validationIssues.push({ message: t(['The day of month is not valid', 'dateInput.validation.dayInvalid']) });
        out.dayValid = false;
    }

    // Month must be 1->12
    if (monthNum < 1 || monthNum > 12) {
        out.validationIssues.push({ message: t(['The month is invalid', 'dateInput.validation.monthInvalid']) });
        out.monthValid = false;
    }

    // Year must be minYear -> maxYear (inclusive)
    if (minYear && yearNum < minYear) {
        out.validationIssues.push({message: t([`The year must be higher or equal to ${minYear}`, 'dateInput.validation.yearTooLow'], {minYear: minYear})});
        out.yearValid = false;
    }
    else if (maxYear && yearNum > maxYear) {
        out.validationIssues.push({ message: t([`The year must be below or equal to ${maxYear}`, 'dateInput.validation.yearTooHigh'], {maxYear: maxYear})});
        out.yearValid = false;
    }

    return out;
}

function UnstyledDateInput({ className, name, value, onChange, minYear, maxYear }: DateInputProps) {
    const { t } = useTranslation();

    if (!value) {
        value = {};
    }

    name = useMemo(() => {
        if (!name) {
            return `date-input-${Math.round(Math.random() * 999999)}`;
        }
        else {
            return name;
        }
    }, [name]);


    function triggerEvent(newValue: DateInputValue) {
        const customEvent = createSyntheticChangeEvent<DateInputValue>(newValue);
        if (onChange) {
            onChange(customEvent);
        }
    }

    function handleChangeDay(e: ChangeEvent<HTMLInputElement>) {
        const newValue = {
            ...value,
            day: e.target.value,
        };
        triggerEvent(newValue);
    }

    function handleChangeMonth(event: ChangeEvent<HTMLSelectElement>) {
        const newValue: DateInputValue = {
            ...value,
            month: event.target.value ?? '',
        };
        triggerEvent(newValue);
    }

    function handleChangeYear(e: ChangeEvent<HTMLInputElement>) {
        const newValue = {
            ...value,
            year: e.target.value,
        };
        triggerEvent(newValue);
    }

    const validationResult = validateDate(value, minYear, maxYear, t);

    const validationMessageType: MessageType = (() => {
        switch(validationResult.filled) {
        case DateInputFillState.EMPTY: {
            return MessageType.INFO;
        }
        case DateInputFillState.PARTIALLY_FILLED: {
            if (validationResult.validationIssues.length > 0) {
                return MessageType.ERROR;
            }
            else {
                return MessageType.INFO;
            }
        }
        case DateInputFillState.FILLED: {
            if (validationResult.validationIssues.length > 0) {
                return MessageType.ERROR;
            }
            else {
                return MessageType.SUCCESS;
            }
        }
        }
    })();
    const validationMessageText: React.JSX.Element = (() => {
        if (validationResult.validationIssues.length > 0) {
            return (
                <ul>
                    {validationResult.validationIssues.map(issue => (
                        <li key={issue.message}>
                            {issue.message}
                        </li>
                    ))}
                </ul>
            );
        }
        else if (validationMessageType === MessageType.SUCCESS) {
            return (
                <span>
                    {t(['Date is valid.', 'dateInput.validation.valid'])}
                </span>
            );
        }
        else {
            return (
                <span>
                    {t(['Date must be in the numerical format', 'dateInput.validation.numerical'])}
                </span>
            );
        }
    })();

    const monthOptions = [
        { label: '---', value: undefined },
        { label: 'JAN', value: 1 },
        { label: 'FEB', value: 2 },
        { label: 'MAR', value: 3 },
        { label: 'APR', value: 4 },
        { label: 'MAY', value: 5 },
        { label: 'JUN', value: 6 },
        { label: 'JUL', value: 7 },
        { label: 'AUG', value: 8 },
        { label: 'SEP', value: 9 },
        { label: 'OCT', value: 10 },
        { label: 'NOV', value: 11 },
        { label: 'DEC', value: 12 },
    ];

    return (
        <div className={`date-input ${className}`}>
            <div className="date-input__day">
                <label htmlFor={`${name}-day`}>{t(['Day', 'dateInput.day'])}</label>
                <input type="text" className={validationResult.dayValid ? '' : 'error'} name={`${name}-day`} id={`${name}-day`} required aria-required="true" value={value.day ?? ''} onChange={handleChangeDay} />
            </div>
            <div className="date-input__month">
                <label htmlFor={`${name}-month`}>{t(['Month', 'dateInput.month'])}</label>
                <select value={value.month} required aria-required="true" onChange={handleChangeMonth}>
                    {monthOptions.map((option, index) => (
                        <option key={index} value={option.value?.toString() ?? undefined}>
                            {option.label}
                        </option>
                    ))}
                </select>
            </div>
            <div className="date-input__year">
                <label htmlFor={`${name}-year`}>{t(['Year', 'dateInput.year'])}</label>
                <input type="text" className={validationResult.yearValid ? '' : 'error'} name={`${name}-year`} id={`${name}-year`} required aria-required="true" value={value.year ?? ''} onChange={handleChangeYear} />
            </div>

            <div className="date-input__info">
                <Message type={validationMessageType} message={validationMessageText} />
            </div>
        </div>
    );
}

//language=SCSS
const DateInput = Styled(UnstyledDateInput)`
& {
    display: flex;
    justify-content: start;
    gap: 16px;
    .date-input__day {
        width: 45px;
        flex-grow: 0;
        flex-shrink: 0;
    }
    .date-input__month {
        width: 74px;
        flex-grow: 0;
        flex-shrink: 0;
    }
    .date-input__year {
        width: 60px;
        flex-grow: 0;
        flex-shrink: 0;
    }
    .date-input__info {
        width: 330px;
        align-self: end;
        ul {
            margin: 0;
            padding-left: 20px;
        }
    }

    .info-message, .error-message, .success-message, .warning-message {
        margin: 0 !important;
    }
    
    &:not(:focus-within) {
        .info-message, .success-message {
            visibility: hidden;
        }
    }
}
`;

export type { DateInputValue };
export { DateInput };
