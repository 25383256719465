import deepEqual from 'deep-eql';
import {Gender} from 'gabi-api-js/hcp/common/hcp_common_pb';
import {PersonalDetails as Caregiver, Language} from 'gabi-api-ts/v2/caregiver/common/caregiver_common';
import {PhoneNumber} from 'gabi-api-ts/v2/hcp/common/hcp_common';
import {ChangeEvent, FormEvent, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import { $enum } from 'ts-enum-util';

import {Button} from '@/components/buttons/button';
import {ErrorMessage} from '@/components/form/error-message';
import {PhonenumberInput, PhoneNumberWithInputInfo} from '@/components/form/input/phonenumber-input';
import {GenderEnum} from '@/enum/gender-enum';
import {useTriggerRender} from '@/hooks/use-trigger-render';
import {Patient} from '@/types/patient';
import {isFocused} from '@/util/dom-util';
import {isEmailValid} from '@/util/email-util';
import {parseIntToGender} from '@/util/gender-util';
import {parseIntToLanguage} from '@/util/language-util';
import {isPhonenumberValid} from '@/util/phonenumber-util';

type PatientCaregiverFormProps = {
    caregiver?: Caregiver;
    className?: string;
    disableLanguageEdition?: boolean;
    loading: boolean;
    patient?: Patient;
    onChanged?: (hasChanged: boolean) => void;
    onSubmit: (caregiver: Caregiver) => void;
}

function PatientCaregiverForm({caregiver, className, disableLanguageEdition, loading, patient, onChanged, onSubmit}: PatientCaregiverFormProps) {
    const { t } = useTranslation();
    const triggerRender = useTriggerRender();
    const [currentCaregiver, setCurrentCaregiver] = useState<Caregiver>(caregiver ?? {
        firstName: '',
        lastName: '',
        email: '',
        preferredLanguage: Language.LANGUAGE_NOT_SET,
        gender: Gender.GENDER_NOT_SET,
        phoneNumber: {
            number: '',
            countryCode: '',
        },
    });

    useEffect(() => {
        checkNotSavedDataCaregiver();
    }, [currentCaregiver]);

    const checkNotSavedDataCaregiver = () => {
        const notSavedDataCaregiver = !deepEqual(currentCaregiver, caregiver);

        if (onChanged) {
            onChanged(notSavedDataCaregiver);
        }
    };

    const emailValid = isEmailValid(currentCaregiver.email);

    const isFilled = () => {
        return (
            currentCaregiver.firstName && currentCaregiver.firstName !== '' &&
            currentCaregiver.lastName && currentCaregiver.lastName !== '' &&
            currentCaregiver.preferredLanguage !== Language.LANGUAGE_NOT_SET &&
            currentCaregiver.gender !== Gender.GENDER_NOT_SET &&
            currentCaregiver.email && currentCaregiver.email !== '' &&
            currentCaregiver.phoneNumber && (
                currentCaregiver.phoneNumber.countryCode && currentCaregiver.phoneNumber.countryCode !== '' &&
                currentCaregiver.phoneNumber.number && currentCaregiver.phoneNumber.number !== ''
            ) &&
            currentCaregiver.phoneNumber &&
            isPhonenumberValid(currentCaregiver.phoneNumber) &&
            emailValid
        );
    };

    const handleChangeFirstName = (e: ChangeEvent<HTMLInputElement>) => {
        const newCaregiver: Caregiver = { ...currentCaregiver };
        newCaregiver.firstName = e.target.value;
        setCurrentCaregiver(newCaregiver);
    };

    const handleChangeLastName = (e: ChangeEvent<HTMLInputElement>) => {
        const newCaregiver: Caregiver = { ...currentCaregiver };
        newCaregiver.lastName = e.target.value;
        setCurrentCaregiver(newCaregiver);
    };

    const handleChangePreferredLanguage = (e: ChangeEvent<HTMLSelectElement>) => {
        const newCaregiver: Caregiver = { ...currentCaregiver };
        const value = (e.target.value === '') ? 0 : parseInt(e.target.value);
        newCaregiver.preferredLanguage = parseIntToLanguage(value);
        setCurrentCaregiver(newCaregiver);
    };

    const handleChangeGender = (e: ChangeEvent<HTMLSelectElement>) => {
        const newCaregiver: Caregiver = { ...currentCaregiver };
        const value = (e.target.value === '') ? 0 : parseInt(e.target.value);
        newCaregiver.gender = parseIntToGender(value);
        setCurrentCaregiver(newCaregiver);
    };

    const handleChangeEmail = (e: ChangeEvent<HTMLInputElement>) => {
        const newCaregiver: Caregiver = { ...currentCaregiver };
        newCaregiver.email = e.target.value;
        setCurrentCaregiver(newCaregiver);
    };

    const handleChangePhoneNumber = (e: ChangeEvent<{value: PhoneNumber}>) => {
        const parsedPhoneNumber = e.target?.value;
        if (parsedPhoneNumber) {
            const newCaregiver: Caregiver = { ...currentCaregiver };
            newCaregiver.phoneNumber = parsedPhoneNumber;
            setCurrentCaregiver(newCaregiver);
        }
        else {
            const newCaregiver: Caregiver = { ...currentCaregiver };
            newCaregiver.phoneNumber = {
                number: '',
                countryCode: '',
            };
            setCurrentCaregiver(newCaregiver);
        }
    };

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (onSubmit) {
            if (onChanged) {
                onChanged(false);
            }
            onSubmit(currentCaregiver);
        }
    };

    return (
        <div className={`patient-caregiver-form ${className ?? ''}`}>
            <form onSubmit={handleSubmit}>
                <p><em>{t(['Fields marked with an asterisk (*) are required.', 'global.mandatoryFields'])}</em></p>
                <div className="multiFieldWrapper">
                    <div className="fieldWrapper">
                        <label htmlFor="patientCaregiver-firstName" className={`mandatoryField ${(currentCaregiver.firstName === '') ? 'empty' : 'filled'}`}>{t(['First name', 'caregiver.firstName'])}</label>
                        <input name="firstName" maxLength={70} id="patientCaregiver-firstName" required aria-required="true" autoFocus value={currentCaregiver.firstName} onChange={handleChangeFirstName} />
                    </div>
                    <div className="fieldWrapper">
                        <label htmlFor="patientCaregiver-lastName" className={`mandatoryField ${(currentCaregiver.lastName === '') ? 'empty' : 'filled'}`}>{t(['Last name', 'caregiver.lastName'])}</label>
                        <input name="lastName" maxLength={70} id="patientCaregiver-lastName" required aria-required="true" value={currentCaregiver.lastName} onChange={handleChangeLastName} />
                    </div>
                </div>
                <div className="multiFieldWrapper">
                    <div className="fieldWrapper">
                        <label htmlFor="patientCaregiver-preferredLanguage" className={`mandatoryField ${(currentCaregiver.preferredLanguage === Language.LANGUAGE_NOT_SET) ? 'empty' : 'filled'}`}>{t(['Preferred language', 'caregiver.preferredLanguage'])}</label>
                        <select name="preferredLanguage" id="patientCaregiver-preferredLanguage" required aria-required="true" value={currentCaregiver.preferredLanguage} onChange={handleChangePreferredLanguage} disabled={disableLanguageEdition}>
                            <option value="" />
                            {$enum(Language).getEntries().filter(lang => lang[1] !== 0).map((language) => (
                                <option key={language[0]} value={language[1]}>{t('global.language.' + language[0])}</option>
                            ))}
                        </select>
                    </div>
                    <div className="fieldWrapper">
                        <label htmlFor="patientCaregiver-gender" className={`mandatoryField ${(currentCaregiver.gender === Gender.GENDER_NOT_SET) ? 'empty' : 'filled'}`}>{t(['Pronoun', 'caregiver.gender'])}</label>
                        <select name="gender" id="patientCaregiver-gender" required aria-required="true" value={currentCaregiver.gender} onChange={handleChangeGender}>
                            <option value="" />
                            {Object.entries(GenderEnum).map((gender) => (
                                <option key={gender[1]} value={gender[1]}>{t('global.gender.' + gender[0])}</option>
                            ))}
                        </select>
                    </div>
                </div>
                <div className="multiFieldWrapper">
                    <div className="fieldWrapper">
                        <label htmlFor="patientCaregiver-email" className={`mandatoryField ${(currentCaregiver.email === '') ? 'empty' : 'filled'}`}>{t(['Email', 'caregiver.email'])}</label>
                        <input
                            type="text"
                            id="patientCaregiver-email"
                            name="email"
                            maxLength={320}
                            required aria-required="true"
                            value={currentCaregiver.email}
                            onBlur={triggerRender}
                            onChange={handleChangeEmail}
                        />

                        {currentCaregiver.email && currentCaregiver.email !== '' && !emailValid && !isFocused('patientCaregiver-email') && (
                            <ErrorMessage errorMessage={t('caregiver.emailErrorMessage') ?? 'Please enter a valid email address'} />
                        )}
                    </div>
                    <div className="fieldWrapper">
                        <label htmlFor="patientCaregiver-phone-number" className={`mandatoryField ${(!currentCaregiver.phoneNumber || currentCaregiver.phoneNumber.number === '') ? 'empty' : 'filled'}`}>{t(['Phone number', 'caregiver.phoneNumber'])}</label>
                        <PhonenumberInput
                            id="patientCaregiver-phonenumber"
                            value={currentCaregiver.phoneNumber}
                            onBlur={triggerRender}
                            onChange={handleChangePhoneNumber}
                        />

                        {currentCaregiver.phoneNumber && !(currentCaregiver.phoneNumber as PhoneNumberWithInputInfo).empty && !isPhonenumberValid(currentCaregiver.phoneNumber) && !isFocused('patientCaregiver-phonenumber') && (
                            <div className="errorWrapper">
                                <ErrorMessage text={''} errorMessage={t('hcpPersonalDataForm.phoneErrorMessage') ?? 'Please enter a valid email address'} />
                            </div>
                        )}
                    </div>
                </div>
                <div className="step-form-actions">
                    <Button className="button-create" disabled={!isFilled()} loading={loading}>{(patient) ? t(['Save', 'caregiver.save']) : t(['Next', 'caregiver.next'])}</Button>
                </div>
            </form>
        </div>
    );
}

export {PatientCaregiverForm};
