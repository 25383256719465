import {useEffect, useState} from 'react';
import {Navigate} from 'react-router-dom';

import {Auth0Service} from '@/services/auth0-service.ts';

function PersonalDataRootPage() {
    const [loading, setLoading] = useState<boolean>(true);
    const [token, setToken] = useState<string | null>(null);

    useEffect(() => {
        (async () => {
            const token = await Auth0Service.getAccessToken();
            setToken(token);
            setLoading(false);
        })();
    }, []);

    if (loading) {
        return null;
    }
    else if (token === null) {
        return (
            <Navigate replace to="/personal-data-error"/>
        );
    }
    else {
        return (
            <Navigate replace to="/personal-data-update"/>
        );
    }
}

export {PersonalDataRootPage};
