import React from 'react';

import {ErrorMessage} from '@/components/form/error-message';
import {InfoMessage} from '@/components/form/info-message';
import {SuccessMessage} from '@/components/form/success-message';
import {WarningMessage} from '@/components/form/warning-message';

enum MessageType {
    INFO,
    SUCCESS,
    WARNING,
    ERROR
}

type MessageProps = {
    className?: string;
    message: string | React.JSX.Element;
    text?: string;
    type: MessageType;
};

function Message({type, className, message, text}: MessageProps) {
    switch(type) {
    case MessageType.INFO:
        return <InfoMessage infoMessage={message} text={text} className={className} />;
    case MessageType.SUCCESS:
        return <SuccessMessage successMessage={message} text={text} className={className} />;
    case MessageType.WARNING:
        return <WarningMessage warningMessage={message} text={text} className={className} />;
    case MessageType.ERROR:
        return <ErrorMessage errorMessage={message} text={text} className={className} />;
    }
}

export { Message, MessageType };
